import React, { useState } from 'react';

const PersonalDetails = () => {
  // Countries visited by year with actual data
  const [travelData] = useState({
    '2025': ['TH', 'SG'],
    '2023': ['FR', 'NL', 'BE', 'CH', 'IT', 'VA', 'ES'],
    '2022': ['TR', 'TH'],
    '2019': ['LK', 'ENGLAND', 'SCOTLAND', 'CZ', 'AT', 'SK', 'HU', 'DK', 'SE', 'IT', 'QA'],
    '2017': ['FR', 'NL', 'DE', 'IT', 'VA', 'GR', 'TR'],
    '2014': ['TH']
  });

  // Create a mapping of country codes to country names
  const countryCodeToName = {
    'TH': 'Thailand',
    'SG': 'Singapore',
    'FR': 'France',
    'NL': 'Netherlands',
    'BE': 'Belgium',
    'CH': 'Switzerland',
    'IT': 'Italy',
    'VA': 'Vatican',
    'ES': 'Spain',
    'TR': 'Turkey',
    'LK': 'Sri Lanka',
    'GB': 'United Kingdom',
    'ENGLAND': 'England',
    'SCOTLAND': 'Scotland',
    'CZ': 'Czech Republic',
    'AT': 'Austria',
    'SK': 'Slovakia',
    'HU': 'Hungary',
    'DK': 'Denmark',
    'SE': 'Sweden',
    'QA': 'Qatar',
    'DE': 'Germany',
    'GR': 'Greece'
  };

  // Function to get flag emoji for a country code
  // const getCountryFlag = (countryCode) => {
  //   // Special case for UK (GB) to show the Union Jack
  //   if (countryCode === 'GB') {
  //     return "🇬🇧";
  //   }
  //   if (countryCode === 'ENGLAND') {
  //     return "🏴󠁧󠁢󠁥󠁮󠁧󠁿";
  //   }
  //   if (countryCode === 'SCOTLAND') {
  //     return "🏴󠁧󠁢󠁳󠁣󠁴󠁿";
  //   }
  //   // Special case for Slovakia
  //   if (countryCode === 'SK') {
  //     return "🇸🇰";
  //   }
  //   // Special case for Vatican
  //   if (countryCode === 'VA') {
  //     return "🇻🇦";
  //   }

  //   const codePoints = countryCode
  //     .toUpperCase()
  //     .split('')
  //     .map(char => 127397 + char.charCodeAt());
  //   return String.fromCodePoint(...codePoints);
  // };

  const getCountryFlag = (countryCode) => {
    // Special cases for non-ISO codes
    const specialFlags = {
      'ENGLAND': <img
        src={`/flags-svg/england.svg`}
        alt={countryCode}
        style={{ width: "20px", height: "15px", objectFit: "contain" }}
      />,
      'SCOTLAND': <img
        src={`/flags-svg/scotland.svg`}
        alt={countryCode}
        style={{ width: "20px", height: "15px", objectFit: "contain" }}
      />,
    };
  
    if (specialFlags[countryCode]) {
      return specialFlags[countryCode];
    }
  
    // Use SVG from FlagCDN for consistent display
    return (
      <img
        src={`/flags-svg/${countryCode.toLowerCase()}.svg`}
        alt={countryCode}
        style={{ width: "20px", height: "15px", objectFit: "contain" }}
      />
    );
  };

  const [activeTab, setActiveTab] = useState('travel');

  // Count total countries (remove duplicates)
  const uniqueCountries = new Set();
  Object.values(travelData).forEach(countries => {
    countries.forEach(country => uniqueCountries.add(country));
  });
  const totalCountries = uniqueCountries.size;

  return (
    <section id="personal-interests" className="py-14 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Personal Interests</h2>
        <div className="flex justify-center mb-8">
          <div className="bg-gray-50 rounded-lg shadow-md p-1">
            <button 
              className={`px-4 py-2 rounded-md ${activeTab === 'travel' ? 'bg-blue-500 text-white' : 'text-gray-700'}`}
              onClick={() => setActiveTab('travel')}
            >
              Travel
            </button>
            <button 
              className={`px-4 py-2 rounded-md ${activeTab === 'hobbies' ? 'bg-blue-500 text-white' : 'text-gray-700'}`}
              onClick={() => setActiveTab('hobbies')}
            >
              Hobbies
            </button>
          </div>
        </div>
        
        {activeTab === 'travel' && (
          <div className="bg-gray-50 rounded-lg shadow-lg p-6">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-semibold mb-4">My Travel Journey</h3>
              <p className="text-gray-700">I've had the privilege of exploring <span className="font-bold text-blue-600">{totalCountries} countries</span> and <span className="font-bold text-blue-600">over 35 cities</span> across the globe, each offering unique experiences and memories.</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {Object.entries(travelData).sort((a, b) => b[0] - a[0]).map(([year, countries]) => (
                <div key={year} className="bg-white rounded-lg p-4 shadow">
                  <h4 className="text-xl font-bold mb-3 text-blue-600 border-b pb-2">{year}</h4>
                  <div className="flex flex-wrap gap-3">
                    {countries.map(country => (
                      <div key={country} className="flex items-center gap-2 bg-gray-50 px-2 py-1 rounded shadow-sm">
                        <span className="text-xl" role="img" aria-label={countryCodeToName[country]}>
                          {getCountryFlag(country)}
                        </span>
                        <span className="text-sm font-medium">{countryCodeToName[country]}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'hobbies' && (
          <div className="bg-gray-50 rounded-lg shadow-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="flex flex-col items-center p-4 rounded-lg bg-gradient-to-br from-blue-50 to-blue-100">
                <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-2">Travel</h3>
                <p className="text-center text-gray-600">I love discovering new places, experiencing different cultures, and trying local cuisines around the world.</p>
              </div>
              
              <div className="flex flex-col items-center p-4 rounded-lg bg-gradient-to-br from-green-50 to-green-100">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-2">Foosball</h3>
                <p className="text-center text-gray-600">I enjoy the fast-paced, competitive nature of foosball, where quick reflexes and strategy make all the difference.</p>
              </div>
              
              <div className="flex flex-col items-center p-4 rounded-lg bg-gradient-to-br from-purple-50 to-purple-100">
                <div className="w-16 h-16 bg-purple-500 rounded-full flex items-center justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-2">PlayStation5 Gaming</h3>
                <p className="text-center text-gray-600">I&apos;m an avid gamer who loves diving into immersive storylines and competing in multiplayer battles.</p>
              </div>
              
              <div className="flex flex-col items-center p-4 rounded-lg bg-gradient-to-br from-amber-50 to-amber-100">
                <div className="w-16 h-16 bg-amber-500 rounded-full flex items-center justify-center mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-2">Open Source</h3>
                <p className="text-center text-gray-600">I&apos;m passionate about open-source development and enjoy contributing to projects that help the community.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PersonalDetails;