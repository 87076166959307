import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Toggle the mobile menu
  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        scrolled ? "bg-white shadow-md py-2" : "bg-transparent py-4"
      }`}
    >
      <div className="container mx-auto px-4 flex justify-between items-center">
        {/* <a href="./" className="text-2xl font-bold text-blue-600">
          <img 
            src="/an-new-logo.png" 
            alt="Logo"
            className="w-40 sm:w-48 md:w-56 lg:w-64 xl:w-72" 
          />
        </a> */}
        <Link
          to="/"          
          className="text-2xl font-bold text-blue-600"
        >
          <img 
            loading="lazy"
            src="/an-new-logo.png" 
            alt="Logo"
            className="w-40 sm:w-48 md:w-56 lg:w-64 xl:w-72" 
          />
        </Link>
        {/* Desktop Nav */}
        <nav className="hidden lg:block">
          <ul className="flex space-x-8">
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("about");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("experience");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Experience
              </Link>            
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("projects");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("skills");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Skills
              </Link>
            </li>            
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("personal-interests");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Personal Interests
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("blogs");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("contact");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {/* Hamburger Button (Mobile) */}
        <button
          className="lg:hidden text-gray-800"
          onClick={handleMenuToggle}
          aria-label="Toggle Menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu (Dropdown) */}
      {isMenuOpen && (
        <nav className="lg:hidden bg-white shadow-md">
          <ul className="flex flex-col space-y-2 p-4">
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("about");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("experience");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Experience
              </Link>            
            </li>            
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("projects");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("skills");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Skills
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("personal-interests");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Personal Interests
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("blogs");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => {
                  closeMenu();
                  setTimeout(() => {
                    const element = document.getElementById("contact");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 100);
                }}
                className="hover:text-blue-600 font-medium transition-colors"
              >
                Contact
              </Link>
            </li>            
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
