import React, { useState } from "react";

const Experience = () => {
  const [activeCompany, setActiveCompany] = useState("alphabyte");

  const companies = {
    alphabyte: {
      name: "Alphabyte",
      header: "Alphabyte - Head of Technology",
      role: "Founding Team & Head of Technology",
      period: "Dec 2023 – present",
      location: "Bengaluru, India",
      achievements: [
        "Engineer #1, led the architecture and development of EX2 AI, an AI-powered Martech platform, leading a team of three engineers. The platform helps marketers/brands/agencies define KPIs and generate ad campaign strategies, including locations, audiences, channels, timing and a clear reasoning around the selections.",
        "Designed and launched a Demand-Side Platform (DSP) with RTB, reporting dashboards, and insights—securing Alphabyte's first clients and generating revenue within two months.",
        "Spearheaded the 2nd phase of EX2 AI with an AI-powered creative generation (banners) and editing tool, streamlining ad design workflows and reducing time to market.",
        "Built EX2 Places, a repository featuring 15,000+ brands and 10 M+ points of interest across SEA and ME regions, centralizing location intelligence for campaign planning.",
        "Developed the complete data platform and established a lean, cost-efficient infrastructure on AWS to support scalable and robust operations."
      ]
    },
    near1: {
      name: "Near Intelligence Inc. (NASDAQ: NIR) - Now Azira",
      header: "Near Intelligence (now Azira) - Principal Engineer",
      role: "Principal Engineer (Director of Engineering)",
      period: "Apr 2021 – Nov 2023",
      location: "Bengaluru, India",
      achievements: [
        "Spearheaded the development and scaling of Near's Real-Time Bidding (RTB) capacity from 5,000 to 400,000 QPS on a lean cluster, maintaining sub-100ms latency at the 99.9th percentile, processing over 13 TB of daily data.",
        "Architected and developed two subsystems within the RTB ecosystem—ID Service & User Segment Service—handling ~4 billion user IDs at RTB scale.",
        "Acted as a core member of Near's Architecture Board (ARBO), driving strategic technical decisions and innovation.",
        "Managed bidder integrations with 15 leading ad exchanges (SSPs), enabling seamless operations for video, native ads, and more.",
        "Architected and owned PlaceMatrix, as part of Near Places, a groundbreaking geo-data repository powering Near's advertising solutions, integrating data from global providers like MasterCard, HERE Maps, and Experian. (~100M POIs, ~45M PlaceMatrix Polygons, ~1.2M Administrative Regions, APIs handling ~60K QPS).",
        "Led the re-architecture of Engage, Near's self-serve DSP, and contributed to Allspark, Near's audience cloud platform, enabling precise audience targeting.",
        "Built and optimized the Carbon Data Platform, managing ID-level data across billions of records, with a Kyoto Tycoon cluster spanning 3 geographies.",
        "Created an Automated GIS Pipeline for ingesting and refreshing global geographic data, improving performance by 30–50x.",
        "Migrated Near's infrastructure from bare-metal servers (IBM Softlayer) to AWS cloud, implementing CI/CD pipelines and ensuring high availability and bringing down costs by close to 40%.",
        "Developed low-latency microservices for geospatial processing (e.g., Geohash refinement) and real-time entity recognition, achieving sub-5ms response times.",
        "Led and mentored a team of 15+ engineers across GIS, RTB, and data platforms, improving collaboration and delivering high-impact projects on schedule."
      ]
    },
    near2: {
      name: "Near Intelligence Inc. (NASDAQ: NIR) - Now Azira",
      header: "Near Intelligence (now Azira) - Lead Engineer",
      role: "Lead, Product Engineering",
      period: "Jul 2018 – Apr 2021",
      location: "Bengaluru, India",
      achievements: [
        "Led product engineering teams focused on developing and enhancing Near's core products",
        "Managed multiple product development initiatives simultaneously",
        "Implemented agile methodologies to improve team productivity and product delivery"
      ]
    },
    near3: {
      name: "Near Intelligence Inc. (NASDAQ: NIR) - Now Azira",
      header: "Near Intelligence (now Azira) - Senior Engineer",
      role: "Senior Engineer, Data Integrations",
      period: "Jul 2016 – Jun 2018",
      location: "Bengaluru, India",
      achievements: [
        "Developed data integration pipelines for multiple data sources",
        "Optimized data processing workflows to improve system performance"
      ]
    },
    near4: {
      name: "Near Intelligence Inc. (NASDAQ: NIR) - Now Azira",
      header: "Near Intelligence (now Azira) - Software Engineer",
      role: "Software Engineer / Associate Software Engineer / Intern",
      period: "Jul 2014 – Jun 2016",
      location: "Bengaluru, India",
      achievements: [
        "Started as an intern and progressed to Software Engineer roles",
        "Contributed to core platform development and system improvements"
      ]
    }
  };

  return (
    <section id="experience" className="py-14 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Professional Experience</h2>        
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/4 mb-6 md:mb-0">
            <div className="sticky top-24">
              <div className="space-y-1 md:pr-4">
                {Object.entries(companies).map(([key, company]) => (
                  <button
                    key={key}
                    className={`w-full text-left px-4 py-3 rounded-lg transition-colors ${
                      activeCompany === key
                        ? "bg-blue-600 text-white font-medium"
                        : "hover:bg-gray-200"
                    }`}
                    onClick={() => setActiveCompany(key)}
                  >
                    {company.name}<br/>
                    {company.period}
                  </button>
                ))}
              </div>
            </div>
          </div>
          
          <div className="md:w-3/4">
            <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
              <div className="mb-6">
                <h3 className="text-xl font-bold text-gray-800">{companies[activeCompany].role}</h3>
                <div className="flex flex-wrap items-center text-gray-600 mt-1">
                  <span className="font-medium text-blue-600">{companies[activeCompany].name}</span>
                  <span className="mx-2">•</span>
                  <span>{companies[activeCompany].period}</span>
                  <span className="mx-2">•</span>
                  <span>{companies[activeCompany].location}</span>
                </div>
              </div>
              
              <ul className="space-y-3">
                {companies[activeCompany].achievements.map((achievement, index) => (
                  <li key={index} className="flex">
                    <svg className="h-5 w-5 text-blue-600 mr-2 mt-0.5 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span>{achievement}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;