import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BlogListing = () => {
  // Sample blog data - replace with your actual blog content
  const [blogs] = useState([
    {
      id: 1,
      title: "How Near reduced latency by four times and achieved 99.9% uptime by migrating to Amazon ElastiCache",
      excerpt: "In this post, we discuss why Near chose to migrate to Amazon ElastiCache for Redis...",
      date: "Jul 23, 2024",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["AWS", "AWS Blogs", "Database", "Redis", "Adtech"],
      image: "/blogs/blog-1-main.png",
      readTime: "10 min read"
    },
    {
      id: 2,
      title: "Real-Time Bidding in Mobile AdTech: Core Entities & Seamless Integration",
      excerpt: "Explore how integrated adtech platforms—from SSPs to DSPs—deliver personalized ads in under 100 milliseconds...",
      date: "March 12, 2025",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["RTB", "Mobile Advertising", "DSP", "SSP", "Exchange", "Adtech"],
      image: "/blogs/blog-2-main.jpeg",
      readTime: "8 min read"
    },
    {
      id: 3,
      title: "The Art of Travel Planning: A Clear Itinerary for a Relaxed Vacation",
      excerpt: "Discover how detailed travel planning not only reduces stress and saves time but also enhances spontaneity, ensuring every moment of your vacation is filled with purpose and relaxation....",
      date: "March 17, 2025",
      author: "Ashwin Nair",
      category: "Travel",
      tags: ["Travel Planning", "Itinerary", "Vacation Tips", "Stress-Free Travel", "Travel Organization"],
      image: "/blogs/blog-3-main.jpeg",
      readTime: "8 min read"
    },
  ]);

  // Filter options
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  
  // Extract unique categories
  const categories = ['All', ...new Set(blogs.map(blog => blog.category))];
  
  // Filter blogs based on search and category
  const filteredBlogs = blogs.filter(blog => {
    const matchesSearch = blog.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          blog.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesCategory = selectedCategory === '' || selectedCategory === 'All' || 
                           blog.category === selectedCategory;
    
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="bg-gray-50 min-h-screen py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">My Blog</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Thoughts, stories, and ideas about web development, travel experiences, and more.
          </p>
        </div>
        
        {/* Search and Filter */}
        <div className="mb-12 flex flex-col md:flex-row gap-4 justify-between max-w-4xl mx-auto">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search posts..."
              className="w-full py-3 pl-10 pr-4 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute left-3 top-3 text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
          </div>
          
          <select
            className="py-3 px-4 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.filter(cat => cat !== 'All').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        
        {/* Blog Posts Grid */}
        {filteredBlogs.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {filteredBlogs.map((blog) => (
              <div key={blog.id} className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-[1.02] duration-300">
                <Link to={`/blog/${blog.id}`}>
                  <img 
                    loading="lazy"
                    src={blog.image} 
                    alt={blog.title} 
                    className="w-full h-48 object-cover"
                  />
                </Link>
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">{blog.category}</span>
                    <span className="mx-2">•</span>
                    <span>{blog.date}</span>
                    <span className="mx-2">•</span>
                    <span>{blog.readTime}</span>
                  </div>
                  <div className="flex flex-wrap gap-2 py-1 ">
                    {blog.tags.map((tag, index) => (
                      <span 
                        key={index} 
                        className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <Link to={`/blog/${blog.id}`}>
                    <h3 className="text-xl font-bold mb-2 hover:text-blue-600 transition-colors">{blog.title}</h3>
                  </Link>
                  <p className="text-gray-600 mb-4">{blog.excerpt}</p>
                  <Link 
                    to={`/blog/${blog.id}`}
                    className="text-blue-600 font-medium inline-flex items-center hover:text-blue-800 transition-colors"
                  >
                    Read More
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <h3 className="text-xl font-medium mb-2">No posts found</h3>
            <p className="text-gray-600">Try adjusting your search or filter criteria</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogListing;