// import React from "react";
// import Header from "./components/Header";
// import Hero from "./components/Hero";
// import About from "./components/About";
// import Experience from "./components/Experience";
// import Skills from "./components/Skills";
// import Projects from "./components/Projects";
// import PersonalDetails from "./components/PersonalDetails";
// import Contact from "./components/Contact";
// import Footer from "./components/Footer";

// function App() {
//   return (
//     <div className="App">
//       <Header />
//       <Hero />
//       <About />
//       <Experience />
//       <Skills />
//       <Projects />
//       <PersonalDetails />
//       <Contact />
//       <Footer />
//     </div>
//   );
// }

// export default App;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Skills from './components/Skills';
import PersonalDetails from './components/PersonalDetails';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BlogPost from './components/BlogPost';
import BlogListing from './components/BlogListing';
import './styles.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <div className="App">
            <Header />
            <Hero />
            <About />
            <Experience />
            <Projects />
            <Skills />
            <PersonalDetails />
            <Blogs />
            <Contact />
            <Footer />
          </div>
        } />
        <Route path="/blog" element={
          <>
            <Header />
            <BlogListing />
            <Footer />
          </>
        } />
        <Route path="/blog/:id" element={
          <>
            <Header />
            <BlogPost />
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;