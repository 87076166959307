// CircleProgress.jsx
import React from 'react';

const CircleProgress = ({ percentage }) => {
  // Circle constants
  const radius = 36;
  const circumference = 2 * Math.PI * radius;

  // Calculate how much of the circle should be visible
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      className="w-8 h-8 transform -rotate-0" // rotate -90° so 0% starts at top
      viewBox="0 0 80 80"
    >
      {/* Background circle */}
      <circle
        className="text-gray-300"
        strokeWidth="8"
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="40"
        cy="40"
      />
      {/* Progress circle */}
      <circle
        className="text-blue-600"
        strokeWidth="8"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx="40"
        cy="40"
        style={{ transition: 'stroke-dashoffset 0.5s' }}
      />
      {/* Center text */}
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        className="text-base fill-current text-blue-600 font-semibold"
      >
        {/* {percentage}% */}
      </text>
    </svg>
  );
};

export default CircleProgress;
