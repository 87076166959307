import React from 'react';
import { useParams, Link } from 'react-router-dom';

const BlogPost = () => {
  const { id } = useParams();
  
  // Sample blog data - replace with your actual blog content or fetch from an API
  const blogPosts = {
    1: {
      title: "How Near reduced latency by four times and achieved 99.9% uptime by migrating to Amazon ElastiCache",      
      content: `
*This blog was pubished by* [AWS](https://aws.amazon.com/blogs/database/how-near-reduced-latency-by-four-times-and-achieved-99-9-uptime-by-migrating-to-amazon-elasticache/).

For several years, Near operated multiple [Kyoto Tycoon](https://dbmx.net/kyototycoon/) clusters for its real-time bidding (RTB) platform. To improve availability and reduce latency, Near migrated to [Amazon ElastiCache](https://aws.amazon.com/elasticache/), a fully managed in-memory caching service.

In this post, we discuss why Near chose to migrate to [Amazon ElastiCache for Redis](https://aws.amazon.com/elasticache/redis/). We also explain the migration process and show how Near optimized its platform to reduce latency and improve availability.

# About Near
Near, a global leader in privacy-led data intelligence, curates the world’s largest source of intelligence on people, places, and products. Near processes data from over 1.6 billion monthly users in 44 countries to empower marketing and operational data leaders to confidently reach, understand, and market to highly targeted audiences and optimize their business results.

# ID unification at Near
One of the fundamental challenges in ad-tech data integration is the use of different unique identifiers like cookies on a browser or ad identifiers on a mobile device for users and individuals. As the datasets convene from heterogeneous sources like internal sources, external data brokers, partners, and public sources, it becomes more challenging to get a 360 degree view of the individuals having no common identifiers across the system.

Near’s proprietary ID unification method, CrossMatrixTM, resolves user identity across devices and places in real time, which anchors its data enrichment platform for marketing needs like managing segmentation, engaging audience and offline attribution by helping businesses curate and target audiences, measuring campaign effectiveness, and much more.

# Why is latency business critical for Near?
With real-time bidding (RTB), impressions are bought and sold programmatically through auctions. To understand why latency is business critical at Near, let’s take a closer look at the various processes and parties involved in RTB.

![Latency Graph](/blogs/blog-1-img-1.png)

First, real-time bidding starts as soon as a user is about to open a mobile app or visit a website. This triggers a bid request from the publisher, which includes various details like the user’s device, operating system, location, and browser. Next, the publisher’s request (with accompanying data) passes to the ad exchange to various advertisers via the ad networks. The ad networks bid on a real-time basis to place ads.

Near’s RTB platform plays a key role with an SLA of 100 milliseconds to bid on the requests received from many of its ad exchange partners across the globe. The entire process, from an app user triggering an ad request to the bidding process to the placement of the ad, happens in just 200 milliseconds.

# Near’s RTB platform
Near built its RTB platform using open-source frameworks like Kafka, Apache Nifi, and Apache Flink, previously deployed on a bare-metal cloud infrastructure from another cloud provider. The company migrated to the AWS Cloud and streamlined its big data processing with a data lake on AWS in 2019.

## Architecture
Let’s look at the initial architecture of Near’s RTB platform post migration to AWS.

The architecture has three key components: the RTB service, analysis traffic, and campaign management service.

The RTB service is the bid traffic ingestion and processing service that decides whether to bid on an impression or not. It’s primarily based on the knowledge about the user — how well the user matches a set of predetermined advertising campaigns with specific budget objectives. For that purpose, it uses the ID manager and user profile microservices, which were backed by a low-latency, persistent key-value datastore, Kyoto Tycoon. After the decision is made, it decides on how much it can bid and then responds to the ad exchanges.

At the backend, Apache Flink and Apache Nifi ingest the traffic to [Amazon Simple Storage Service](https://aws.amazon.com/s3/) (Amazon S3). With a data lake built on Amazon S3, Near preserves and processes the historical data for transformation, enrichment, and preparation for rich analytics. This data is critical for future bid traffic because it helps make the decision on how much a given impression is worth to the advertiser and how likely it is that this impression will stick with the app or website user.

Analysis traffic also comes from various third-party and public data providers. Sometimes, the data comes directly from content publishers through tracking pixels, though the scale is low. Later, all this enriched and analyzed data is fed back to the low-latent data store via APIs.

The campaign management service provides advertisers a software as a service (SaaS) platform for managing their advertising campaigns and getting detailed insights of the campaigns. It uses [Amazon Redshift](https://aws.amazon.com/redshift) for analytics.

## The challenges
Now that we understand Near’s RTB platform, let’s take a close look at the challenges Near had with its low-latency data store using the Kyoto Tycoon (KT) server, which powered the platform for years before the migration to ElastiCache. KT is a handy cache and storage server written in C++, and is a lightweight open-source database server. It is a package of network interface to the DBM called Kyoto Cabinet.

When Near started to build its RTB platform, it explored various solutions for caching its user profiles data and originally chose KT for a couple of reasons:

- KT can run 1 million queries in 25 seconds, and supports 40,000 queries per second (QPS)
- KT supports high availability via hot backup, asynchronous replication, and dual master topology

By that time, Near had around 200 million IDs and had to support 500 million requests each day, or 8,000 QPS. With the KT dual primary server setup on [Amazon Elastic Compute Cloud](http://aws.amazon.com/ec2) (Amazon EC2) instances across Availability Zones, Near was able to meet the business requirements for years.

When Near started expanding and operating in Singapore, India, Australia, and other Southeast Asian countries, its user profile data started growing rapidly — 200 million unique users per day. Consequently, its RTB platform was expected to perform much higher than what KT could support (more than 40,000 QPS). Near started facing challenges with its KT setup, which prompted the team to invest in changing its RTB platform cache storage solution.

### Increased operational cost
Because the previous KT server was set up on EC2 instances, Near’s DevOps team had to do the heavy lifting of provisioning, backups, sharding, scaling, bringing up the new primary in case of primary node failure, and more. All these manual operations increased the total cost of ownership for Near.

In addition, the open-source development of Kyoto Tycoon seemed to have halted around 2012. From the dates of the articles, [Stack Overflow answers](https://stackoverflow.com/search?q=Kyoto+Tycoon), client repos, and presentations, Near found it difficult to operate with its KT server setup with no support from its author or user community.

### Performance challenges when scaling
Today, Near has more than 1.6 billion user IDs and profiles, and supports over 10 billion requests per day (120,000 QPS). The KT dual primary server setup, which initially supported Near’s expectations, couldn’t support this business scale.

Also, monitoring the KT server performance didn’t come easily, although ktremotemgr (a KT database client utility) exposes a few metrics. Near had to monitor mostly the EC2 instances that hosted the KT servers.

### Read-after-write consistency
As the data volume grew, Near observed replication lag with its secondary because it couldn’t keep up with the updates occurring on the primary. Unapplied changes got accumulated in the secondary’s update logs. This resulted in data inconsistencies when failover happened, though it didn’t affect the RTB service.

### Increased RTO
As a hot-fix, Near went ahead with a single KT primary with a hot backup and updated logs configuration for its data durability and availability requirements. This was working out well even when the primary node failed, because it had the golden Amazon AMI of its KT server and hot backups to bring back the primary, satisfying its Recovery Time and Recovery Point Objectives (RTO and RPO).

In the last 7 years, around 10 such hardware issues happened with its KT primary instance. And the last couple of incidents happened in 2019 itself, where the recovery time spiked up to 4 hours. This prompted the need for an architectural change and eventually the replacement of its cache datastore, the KT server, with a highly available, high performant cache server with minimal operational cost.

# Migration journey to ElastiCache
When Near started exploring options to replace its cache data store, we found Amazon ElastiCache for Redis to be the best match for the RTB platform.

Redis is an open-source project supported by a vibrant community, including AWS. It’s also [Stack Overflow’s](https://insights.stackoverflow.com/survey/2021) most loved database for the past 5 years.

ElastiCache for Redis is a fully managed, highly scalable, secure in-memory caching service that can support the most demanding applications requiring sub-millisecond response times. It also offers built-in security, backup and restore, and cross-Region replication capabilities.

Near chose to replace its KT server setup on EC2 instances with ElastiCache for Redis to accomplish the following:

- Lower the Total Cost of Ownership (TCO)
- Improve performance and operational excellence
- Achieve highly availability

Let’s take a closer look at Near’s migration journey to ElastiCache.

## Choosing the right node size
Choosing the right node size and instance type play a key role while designing the solution. We used the size of an item to be cached and the number of items, to arrive at an estimate:

Size of an item = 50 characters = 50 * 4 Bytes = 200 Bytes (A)
Number of items = 1 billion Keys (B)
Memory requirements = (A) * (B) = 200 GB

We estimated that 200 GB of memory was required for Near’s data, and chose cache.r6g.8xlarge (32 vCPUs 209.55 GB), the current generation [Graviton2](https://aws.amazon.com/ec2/graviton/) memory-optimized instances. The following diagram shows the architecture that we came up with: having two shards and one read replica per shard considering availability, performance, and backup process requirements.

![Application](/blogs/blog-1-img-2.png)

## Application preparedness
The RTB service was updated to perform API invocations on the ElastiCache cluster using the Java Redis client Jedis and the [AWS SDK for Java](https://aws.amazon.com/sdk-for-java).

## Data migration
To seed the ElastiCache for Redis storage, we used the long-term data stored in Amazon S3.

First, we scoped the latest 4 months of data and cleansed it to remove any duplicates. Next, we wrote a Spark job using the [aws-sdk-java](https://github.com/aws/aws-sdk-java-v2/#using-the-sdk) and [hadoop-aws](https://hadoop.apache.org/docs/stable/hadoop-aws/tools/hadoop-aws/index.html) libraries to read the data from Amazon S3 and transform it to a specific format as follows:

#Kyoto_DB_data.txt
SET Key0 Value0 
SET Key1 Value1
…
SET KeyN ValueN

Lastly, we used Redis mass insertion to load the data into the ElastiCache clusters using the pipe mode:

$ cat Kyoto_DB_data.txt | redis-cli --pipe

## Switchover
After testing various use cases, along with the application code changes deployment, the internal-facing KT cluster IP was replaced with the ElastiCache cluster DNS name to switch over to the new caching server.

## Decommissioning the KT server
After a month’s time observing the new cache server performance in the production environment, the EC2 instances that hosted the KT servers were decommissioned.

# The outcome
In this section, we discuss the outcome of this new architecture.

## Total Cost of Ownership
The Near DevOps team no longer needs to perform heavy lifting operations like hardware provisioning, software patching, setup, configuration, monitoring, failure recovery, and backups. ElastiCache, as a fully managed in-memory service, scales out and in based on application requirements.

## Performance
Data resides in memory with ElastiCache for Redis so it doesn’t require access to disk. The result is lightning-fast performance with average read and write operations taking less than a millisecond, with support for millions of operations per second.

After migrating to ElastiCache, Near saw close to four times faster read and write performance on its user profile and ID management services — reducing latency from 15 milliseconds to 4 milliseconds post migration.

## Operational excellence
Monitoring is vital in maintaining the reliability, availability, and performance of the Redis cache server. And ElastiCache exposes metrics like cache hits, cache misses, concurrent connections, latency, replication lag, and more via [Amazon CloudWatch](https://aws.amazon.com/cloudwatch/). With the data and insights, Near has set CloudWatch alarms to set thresholds on metrics and trigger notifications when preventive actions are needed.

## High availability
With two shards spanning across Availability Zones, a read replica enabled per shard, and automatic failover, Near’s low-latency cache becomes highly available.

Thanks to the failover feature of ElastiCache, we simulated failure of a primary node in the ElastiCache cluster and verified how the read replica gets automatically promoted as a new primary node in a few seconds and a new replica gets launched transparently.

Near also scheduled automated backups that use Redis’s native strategy, generating .rdb files, exported to an S3 bucket. This yields an RPO of 1 day (automatic backup) and RTO based on the backup size to help Near against cache data loss in case of a Regional failure.

## Next step: Cost optimization
Unlike traditional databases, all the data is in memory with ElastiCache for Redis, which provides low latency and high throughput data access. And it comes with a cost.

Now Near’s ElastiCache cluster stores more than a billion records, with 3 months’ retention capacity (attribution window), which doubled their spend on AWS compared to its KT server setup on EC2 instances. To optimize the spend, Near has planned the next step: keeping only the hot data, the last 24 hours of data, in the cache. This is roughly around a few millions records in the ElastiCache cluster. From there, Near will move the cold data to a persistent key-value data store to cut costs.

# Conclusion
Near was able to reduce latency by four times and achieve 99.9% uptime of its critical RTB platform applications by moving to ElastiCache. Before migration, Near’s DevOps and development engineers had to spend around 4 hours when they faced issues with their self-managed Kyoto Tycoon deployment. Now, Near’s engineers are able to dedicate more time to innovation and building new features rather than spending their valuable time in heavy lifting, infrastructure-related operations. While we work on optimising the cost, Near continues to explore adopting additional ElastiCache for Redis features such as [Auto Scaling](https://aws.amazon.com/elasticache/redis/auto-scaling/) and [Global Datastore](https://aws.amazon.com/elasticache/redis/global-datastore/) considering future scalability needs.`.trim(),
      date: "Jul 23, 2024",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["AWS", "AWS Blogs", "Database", "Redis", "Adtech"],
      image: "/blogs/blog-1-main.png",
      readTime: "10 min read"
    },
    2: {
      title: "Real-Time Bidding in Mobile AdTech: Core Entities & Seamless Integration",      
      content: `
With experience scaling RTB systems to handle over 400,000 queries per second at Near Intelligence (now Azira), I've gained deep insights into the architecture and integration of mobile adtech platforms. This post breaks down the core entities within a mobile RTB ecosystem and explains how they interact to deliver personalized ads in milliseconds.

## RTB Fundamentals: The 100ms Challenge

Real-Time Bidding is the backbone of programmatic advertising, enabling automated auctions for digital ad inventory in real time. The entire process—from the bid request to ad delivery—must be completed in under 100 milliseconds. To put this into perspective, consider that a typical blink takes about 300 milliseconds. This extraordinary speed requirement underscores the complexity and precision needed in RTB systems.

## Core Entities in a Mobile RTB Ecosystem

### 1. Supply-Side Platforms (SSPs)

SSPs represent publishers and manage their ad inventory. In the mobile space, these typically include:
- Mobile app developers
- Mobile game companies
- Mobile content publishers

**Key responsibilities include:**
- Packaging ad inventory with relevant user context
- Generating and distributing bid requests
- Enforcing ad quality standards
- Facilitating the auction process

### 2. Demand-Side Platforms (DSPs)

DSPs represent advertisers looking to purchase ad impressions. They serve as the bidding engines that evaluate inventory and place bids based on:
- User targeting parameters
- Campaign goals
- Budget constraints
- Bidding strategies

**Essential components include:**
- Bid evaluation engine
- User identification service
- Audience segment service
- Campaign management system
- Creative selection logic

### 3. Data Management Platforms (DMPs)

DMPs act as the intelligence layer by:
- Collecting and organizing user data
- Creating detailed audience segments
- Enriching bid requests with additional signals
- Enabling advanced targeting capabilities

### 4. Ad Exchanges

Ad exchanges serve as the intermediary between SSPs and DSPs by providing:
- Standardized communication protocols (e.g., OpenRTB)
- Auction management systems
- Fraud prevention mechanisms
- Robust reporting infrastructure

### 5. User Identification Services

These services handle the challenging task of recognizing users across multiple devices and sessions through methods such as:
- Mobile advertising IDs (IDFA, AAID)
- Probabilistic matching techniques
- Encrypted user identifiers
- Identity graphs

## The RTB Workflow: Integration in Action

The RTB process unfolds in several key stages:

### 1. Bid Request Generation (0-10ms)

When a user opens an app that has an available ad slot:
- The app's SDK detects an ad opportunity.
- It collects contextual information including:
  - Device details
  - App metadata
  - User identifiers
  - Location data (with permission)
  - Available ad dimensions
- This data is packaged into a bid request and sent to the SSP.

### 2. Bid Request Enrichment (10-25ms)

The SSP enhances the bid request by adding:
- Publisher information
- Floor pricing details
- Ad format specifications
- First-party data (if available)

The enriched request is then forwarded to an ad exchange, which distributes it to multiple DSPs.

### 3. Bid Evaluation (25-60ms)

Upon receiving the bid request, the DSP must rapidly:
- Identify the user via its ID service (*sub-5ms*)
- Match the user to relevant audience segments (*sub-10ms*)
- Check campaign eligibility
- Select the appropriate creatives
- Calculate the bid price
- Generate a bid response

This entire evaluation process must occur within roughly 35ms to remain competitive.

### 4. Auction Resolution (60-80ms)

The ad exchange then:
- Collects all bids
- Determines the winning bid using the applicable auction rules (first-price, second-price, etc.)
- Generates win/loss notifications
- Records the auction results for subsequent reporting

### 5. Ad Serving (80-100ms)

Finally, once a winner is determined:
- The winning DSP is notified.
- The ad markup is delivered back to the SSP.
- Creative assets are served to the user's device.
- Impression trackers are fired to log the ad view.

## Technical Challenges and Solutions

RTB systems must overcome several technical hurdles to meet the sub-100ms performance target. Here’s an overview of key challenges and their solutions:

### 1. User Identification at Scale

**Challenge:** Maintaining a database of billions of users accessible in under 5ms is crucial for effective targeting.  
**Solution:**
- Leverage distributed in-memory databases with custom sharding strategies.
- Use geographically distributed clusters (e.g., KyotoTycoon) and user ID fingerprinting for optimal routing.
- Implement multi-tiered caching architectures and probabilistic data structures for rapid initial checks.

### 2. Real-Time Segment Service

**Challenge:** Evaluating thousands of audience segments per bid request in mere milliseconds.  
**Solution:**
- Utilize bitmap-based segment representations, including **roaring bitmaps** for efficient storage.
- Apply *Bloom filters* for rapid negative lookups.
- Organize segments by recency and geography, and conduct parallel segment evaluations.

### 3. Geography Processing

**Challenge:** Determining accurate location context from raw coordinates in real time.  
**Solution:**
- Implement hierarchical spatial indexing with pre-computed geofences for common areas.
- Use multi-level geohash lookups and in-memory R-trees for processing complex polygon data.
- Adapt precision based on population density for efficient location mapping.

### 4. Bid Stream Processing

**Challenge:** Managing and analyzing terabytes of bid requests daily to extract actionable insights.  
**Solution:**
- Design a multi-staged data pipeline incorporating real-time stream processing (e.g., Kafka with custom processors).
- Use time-windowed operations for data aggregation.
- Distinguish between hot-path and cold-path data, and employ columnar storage (such as ClickHouse) for analytics.

## Integration Best Practices

Based on extensive experience integrating with over 15 leading ad exchanges, consider these best practices:

### 1. Standardize on OpenRTB

- **Implement strict OpenRTB protocol compliance** with version negotiation.
- Develop adapter layers to:
  - Normalize exchange-specific extensions.
  - Handle differences across protocol versions (2.3, 2.5, 3.0).
  - Validate schema before processing.
  - Log any protocol violations for effective debugging.

### 2. Implement Graceful Degradation

RTB systems must always respond, even in the face of subsystem failures:
- Use circuit breakers for dependent services.
- Establish default bidding behaviors when targeting data isn’t available.
- Monitor response times with early termination strategies.
- Opt for gradual feature degradation rather than complete system failure.

### 3. Design for Regional Compliance

Given that advertising regulations vary globally:
- Implement geography-aware data handling.
- Build configurable consent management systems.
- Create jurisdiction-specific bidding logic.
- Develop privacy-safe processing pipelines tailored to regional requirements.

### 4. Monitor Both Technical and Business Metrics

It’s crucial to track not only system performance but also business outcomes:
- Monitor bid/win ratios by exchange.
- Track timeout rates by geography.
- Evaluate segment match rates.
- Measure creative delivery success rates.
- Assess end-to-end latency across the entire stack.

## Conclusion

Building an effective RTB system for mobile advertising requires a deep integration of multiple specialized entities—each addressing unique challenges under extreme time constraints. Success hinges on intelligent system design that balances precision with performance, ensuring that every process is optimized for the sub-100ms window.

As mobile advertising continues to evolve with new privacy regulations, enhanced device capabilities, and emerging ad formats, the underlying RTB architecture must remain agile and robust. The most successful systems achieve this through careful entity integration, optimized data flows, and continuous performance tuning.

If you’re navigating the complexities of RTB or looking to optimize your adtech stack, let’s connect—I’m always open to sharing insights and learning from others. Reach out via [LinkedIn](https://www.linkedin.com/in/ashwin-nair7) or explore my projects on [GitHub](https://github.com/ashwin711).
  `.trim(),
      date: "March 12, 2025",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["RTB", "Mobile Advertising", "DSP", "SSP", "Exchange", "Adtech"],
      image: "/blogs/blog-2-main.jpeg",
      readTime: "8 min read"
    },
    3: {      
      title: "The Art of Travel Planning: A Clear Itinerary for a Relaxed Vacation",
      content: `

A dynamic scene captures a traveler at a cozy café, intently reviewing a detailed itinerary on a digital tablet. Surrounding the device are soft illustrations of travel icons—*clocks*, *maps*, *compasses*—that symbolize time management and exploration. The background subtly hints at scenic destinations with glimpses of iconic landmarks and natural landscapes. A harmonious blend of warm and cool hues evokes both the excitement of adventure and the tranquility of a well-organized journey, perfectly illustrating the balance between structured planning and spontaneous travel.

## The Importance of Clear Planning During Travel

Traveling offers freedom and adventure, yet without a clear plan, that freedom can sometimes lead to stress and wasted time. A well-structured itinerary is not about over-scheduling your vacation—it’s about ensuring every moment is well-spent, reducing indecision, and increasing overall enjoyment.

## Key Benefits of Detailed Travel Planning

A detailed plan offers several advantages:
- **Time Efficiency:** With every hour planned, you minimize the time wasted deciding what to do next.
- **Stress Reduction:** Knowing your schedule in advance reduces anxiety and the mental burden of constant decision-making.
- **Balanced Experience:** A clear itinerary ensures a perfect mix of sightseeing and downtime, so you can relax without guilt.
- **Enhanced Spontaneity:** When your main activities are scheduled, you have the freedom to embrace unexpected opportunities during leisure periods.
- **Prioritization:** Planning helps you identify and prioritize must-see attractions and experiences.

## Addressing Common Misconceptions

Many travelers worry that planning every detail might kill the spontaneity of a trip. However:
- **Planning Empowers:** A clear roadmap boosts your confidence and encourages you to explore.
- **Built-in Flexibility:** A good plan always includes free time blocks, allowing for spontaneous adventures or simply relaxing.
- **Reduced Uncertainty:** With your schedule mapped out, you eliminate the constant dilemma of "what’s next?" and can fully enjoy each moment.

## The Downsides of Not Planning

Traveling without a clear plan can lead to several issues:
- **Wasted Time:** Excessive time spent deciding what to do can eat into your vacation hours.
- **Missed Opportunities:** Without prior research, key attractions or experiences may be overlooked.
- **Increased Stress:** Constant uncertainty about your next move can lead to mental fatigue.
- **Inefficient Itineraries:** Unplanned travel often results in disorganized routes and less fulfilling days.

## How to Effectively Plan Your Travel Itinerary

Crafting an effective itinerary doesn’t mean every minute is booked; it means you have a clear roadmap to enhance your trip:
- **Plan Hour-by-Hour:** Map out your day with key activities while leaving buffer time for rest.
- **Prioritize Attractions:** Identify your must-see spots and allocate ample time for them.
- **Include Flexibility:** Leave open periods for spontaneous adventures or simply unwinding.
- **Leverage Technology:** Use travel apps and online resources to manage your schedule and navigate new places.
- **Review and Adjust:** Regularly revisit your plan to make tweaks based on new discoveries or changing energy levels.

## Conclusion

Clear travel planning is a powerful tool that enhances your overall vacation experience. By eliminating wasted time and reducing stress, you can focus on the joy of exploration. A thoughtfully crafted itinerary balances structure with spontaneity, ensuring every moment of your trip is both relaxing and enriching.

Embrace planning as a means to elevate your travel experience, and enjoy the perfect blend of adventure and leisure on your next journey.
      `,
      date: "March 17, 2025",
      author: "Ashwin Nair",
      category: "Travel",
      tags: ["Travel Planning", "Itinerary", "Vacation Tips", "Stress-Free Travel", "Travel Organization"],
      image: "/blogs/blog-3-main.jpeg",
      readTime: "8 min read"
    },
  };
  
  function parseInlineMarkdown(text) {
    // Regex that captures:
    // 1) Bold: **some text**
    // 2) Italics: *some text*
    // 3) Links: [link text](url)
    //
    // Explanation of groups:
    // - (\\*\\*([^*]+)\\*\\*) captures **bold** text (group2 = the text inside)
    // - (\\*([^*]+)\\*) captures *italic* text (group4 = the text inside)
    // - (\\[([^\\]]+)\\]\\(([^)]+)\\)) captures [text](url) links (group6 = full match, group7 = link text, group8 = link URL)
    //
    // We also need to ensure we don't mix them up. So we use alternation carefully.
    // We also allow multiple matches in one line.
    const pattern = /(\*\*([^*]+)\*\*)|(\*([^*]+)\*)|(\[([^\]]+)\]\(([^)]+)\))/g;
  
    const elements = [];
    let lastIndex = 0;
    let match;
  
    while ((match = pattern.exec(text)) !== null) {
      // text before the match
      const textBefore = text.slice(lastIndex, match.index);
      if (textBefore) {
        elements.push(textBefore);
      }
  
      // Check which group matched
      if (match[2]) {
        // Group2 => Bold
        elements.push(
          <strong key={match.index} className="font-semibold">
            {match[2]}
          </strong>
        );
      } else if (match[4]) {
        // Group4 => Italics
        elements.push(
          <em key={match.index} className="italic">
            {match[4]}
          </em>
        );
      } else if (match[6]) {        
        const linkText = match[6];
        const linkUrl = match[7];
        elements.push(
          <a
            key={match.index}
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            {linkText}
          </a>
        );
      }
  
      lastIndex = pattern.lastIndex;
    }
  
    // leftover text after last match
    const remainingText = text.slice(lastIndex);
    if (remainingText) {
      elements.push(remainingText);
    }
  
    return elements;
  }

  const blog = blogPosts[id];
  
  if (!blog) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <h2 className="text-3xl font-bold mb-4">Blog Post Not Found</h2>
        <p className="mb-8">The blog post you're looking for doesn't exist or has been removed.</p>
        <Link to="/blog" className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700">
          Return to Blog
        </Link>
      </div>
    );
  }
  
  return (
    <div className="bg-white pt-24 pb-16">
      {/* Header */}
      <div className="w-full bg-gradient-to-r from-blue-500 to-purple-600 py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center text-white">
            <Link to="/blog" className="inline-flex items-center text-white mb-6 hover:text-blue-100 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
              Back to Blog
            </Link>
            <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
            <div className="flex items-center justify-center text-sm">
              <span className="bg-white bg-opacity-20 text-white px-3 py-1 rounded">{blog.category}</span>
              <span className="mx-2">•</span>
              <span>{blog.date}</span>
              <span className="mx-2">•</span>
              <span>{blog.readTime}</span>
            </div>
            <div className="flex items-center justify-center flex-wrap gap-2 pt-4 ">
              {blog.tags.map((tag, index) => (
                <span 
                  key={index} 
                  className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Featured Image */}
      <div className="container mx-auto px-4 -mt-16">
        <div className="max-w-4xl mx-auto pt-20">
          <img 
            loading="lazy"
            src={blog.image} 
            alt={blog.title} 
            className="w-full h-64 md:h-96 object-cover rounded-xl shadow-lg"
          />
        </div>
      </div>
      
      {/* Blog Content */}
      <div className="container mx-auto px-4 mt-12">
        <div className="max-w-3xl mx-auto">
          <div className="prose prose-lg max-w-none">
            {blog.content.split('\n').map((paragraph, idx) => {
              if (paragraph.startsWith('# ')) {
                return <h1 key={idx} className="text-3xl font-bold mt-8 mb-4">{parseInlineMarkdown(paragraph.substring(2))}</h1>;
              } else if (paragraph.startsWith('## ')) {
                return <h2 key={idx} className="text-2xl font-bold mt-6 mb-3">{parseInlineMarkdown(paragraph.substring(3))}</h2>;
              } else if (paragraph.startsWith('### ')) {
                return <h3 key={idx} className="text-xl font-bold mt-5 mb-2">{parseInlineMarkdown(paragraph.substring(4))}</h3>;
              } else if (paragraph.startsWith('- ')) {
                return <li key={idx} className="ml-6 mb-1">{parseInlineMarkdown(paragraph.substring(2))}</li>;
              } else if (paragraph.startsWith('1. ')) {
                return <li key={idx} className="ml-6 mb-1 list-decimal">{parseInlineMarkdown(paragraph.substring(3))}</li>;
              } else if (paragraph.trim().startsWith('![')) {
                // Try to parse something like: ![alt text](https://example.com/image.jpg)
                const match = paragraph.trim().match(/^!\[(.*?)\]\((.*?)\)/);
                if (match) {
                  const altText = match[1];
                  const imgUrl = match[2];
                  return (
                    <img
                      loading="lazy"
                      key={idx}
                      src={imgUrl}
                      alt={altText}
                      className="my-4 mx-auto max-w-full h-auto"
                    />
                  );
                }                
                return null;
              } else if (paragraph.trim() !== '') {
                return <p key={idx} className="mb-4 leading-relaxed">{parseInlineMarkdown(paragraph)}</p>;
              }
              return null;
            })}
          </div>
          
          {/* Author Section */}
          {/* <div className="mt-12 p-6 bg-gray-50 rounded-lg flex items-center">
            <div className="w-16 h-16 bg-blue-600 text-white rounded-full flex items-center justify-center text-xl font-bold">
              {blog.author.split(' ').map(name => name[0]).join('')}
            </div>
            <div className="ml-4">
              <p className="font-bold">{blog.author}</p>
              <p className="text-gray-600 text-sm">Author</p>
            </div>
          </div> */}

          <div className="mt-12 p-6 bg-gray-50 rounded-lg flex items-center">            
            <div className="w-16 h-16 rounded-full overflow-hidden bg-blue-600 text-white flex items-center justify-center">
              <img
                loading="lazy"
                src={`/blogs/authors/${blog.author
                  .toLowerCase()
                  .replace(/\s+/g, '-')}.jpeg`}
                alt={blog.author}
                className="w-full h-full object-cover"
              />
            </div>
            
            {/* Author Details */}
            <div className="ml-4">
              <p className="font-bold">{blog.author}</p>
              <p className="text-gray-600 text-sm">Author</p>
            </div>
          </div>
          
          {/* Navigation */}
          <div className="mt-12 flex justify-between">
            <Link 
              to="/blog" 
              className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
              Back to All Posts
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;