import React from "react";

const About = () => {
  return (
    <section id="about" className="py-14 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">About Me</h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <div className="relative max-w-md mx-auto">
              <div className="absolute inset-0 bg-blue-100 rounded-lg transform translate-x-3 translate-y-3"></div>
              <div className="relative bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-1">Ashwin Nair</h3>
                  {/* <p className="text-gray-500">Born: November 7</p> */}
                </div>
                <div className="space-y-2">
                  <div className="flex items-start">
                    <svg className="h-5 w-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <p>Bengaluru, Karnataka, India</p>
                  </div>
                  {/* <div className="flex items-start">
                    <svg className="h-5 w-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <p>ashwinnair.711@gmail.com</p>
                  </div>
                  <div className="flex items-start">
                    <svg className="h-5 w-5 text-blue-600 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <p>+91 9620645370</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="max-w-lg">
              <p className="text-gray-700 mb-4">
                I'm a Software Engineer with over 10 years of experience in building and scaling high-performance systems. Currently, I'm heading technology at Alphabyte, where I lead the development of AI-powered adtech / martech products.
              </p>
              <p className="text-gray-700 mb-4">
                My work involves architecting scalable, low-latency platforms, developing innovative solutions, and building SaaS products. I have experience working through different stages of a company's growth, from an early-stage startup to an IPO and beyond.
              </p>
              <p className="text-gray-700 mb-4">
                As a founding team member at Alphabyte and previously as a Principal Engineer at Near Intelligence Inc. (NASDAQ: NIR), I've led the development of complex systems handling hundreds of thousands of queries per second while maintaining sub-100ms latency.
              </p>
              <p className="text-gray-700">
                I'm passionate about distributed systems, geospatial technology, and building efficient, scalable architecture to solve challenging business problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;