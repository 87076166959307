import React from "react";

const Projects = () => {
  const projects = [
    {
      name: "Georaptor",
      description: "A Python-based geohash compression tool which creates the best combination of geohashes across various levels to represent a polygon. Used in GIS applications to improve spatial indexing and reduce database storage.",
      stats: { stars: 189, forks: 16 },
      link: "https://github.com/ashwin711/georaptor"
    },
    {
      name: "ProximityHash",
      description: "Generates a set of geohashes that cover a circular area, given the center coordinates and the radius. It also has an additional option to use GeoRaptor that creates the best combination of geohashes.",
      stats: { stars: 271, forks: 33 },
      link: "https://github.com/ashwin711/proximityhash"
    },
    {
      name: "Loc2country",
      description: "Location coordinates (lat/lon) to ISO alpha-3 country code with microsecond latencies.",
      stats: { stars: 260, forks: 12 },
      link: "https://github.com/soorajb/loc2country"
    },
    {
      name: "EX2 AI",
      description: "AI-powered Martech platform helping marketers define KPIs and generate ad campaign strategies with audience selection, channel planning, and timing optimization.",
      link: null
    },
    {
      name: "NearPlaces",
      description: "Groundbreaking geo-data repository integrating data from global providers like HERE Maps, OpenStreet Maps, and more with ~100M POIs and ~45M PlaceMatrix Polygons.",
      link: null
    },
    {
      name: "Allspark",
      description: "Platform to help marketing leaders maximize campaign effectiveness with custom audience curation, omnichannel activation, and real-world attribution.",
      link: null
    }
  ];

  return (
    <section id="projects" className="py-14 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Notable Projects</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 flex flex-col h-full transition-transform hover:transform hover:-translate-y-1 hover:shadow-lg">
              <div className="p-6 flex-grow">
                <h3 className="text-xl font-bold mb-2 text-blue-600">{project.name}</h3>
                <p className="text-gray-700 mb-4">{project.description}</p>
                
                {project.stats && (
                  <div className="flex space-x-4 text-sm text-gray-500">
                    <div className="flex items-center">
                      <svg className="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <span>{project.stats.stars} stars</span>
                    </div>
                    <div className="flex items-center">
                      <svg className="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      <span>{project.stats.forks} forks</span>
                    </div>
                  </div>
                )}
              </div>
              
              <div className="px-6 py-3 bg-gray-50 border-t border-gray-200">
                {project.link ? (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 font-medium flex items-center"
                  >
                    View on GitHub
                    <svg className="ml-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </a>
                ) : (
                  <span className="text-gray-500 italic">Proprietary project</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;