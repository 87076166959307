// import React from "react";

// const Skills = () => {
//   const skillCategories = [
//     {
//       title: "Programming Languages",
//       skills: [
//         { name: "Java", level: 95 },
//         { name: "Python", level: 90 },
//         { name: "Golang", level: 75 },
//         { name: "JavaScript/React.js", level: 70 }
//       ]
//     },
//     {
//       title: "Databases & Data Stores",
//       skills: [
//         { name: "Elasticsearch", level: 90 },
//         { name: "Redis", level: 90 },
//         { name: "MongoDB", level: 85 },
//         { name: "ClickHouse", level: 85 },
//         { name: "PostgreSQL", level: 80 },
//         { name: "Cassandra", level: 75 },
//         { name: "KyotoTycoon", level: 90 }
//       ]
//     },
//     {
//       title: "Cloud & DevOps",
//       skills: [
//         { name: "AWS Cloud", level: 85 },
//         { name: "Docker", level: 80 },
//         { name: "CI/CD", level: 75 }
//       ]
//     },
//     {
//       title: "Frameworks & Technologies",
//       skills: [
//         { name: "Spring Framework", level: 90 },
//         { name: "Dropwizard Framework", level: 90 },
//         { name: "Apache Kafka", level: 85 },
//         { name: "Apache Spark", level: 85 },
//         { name: "GIS", level: 95 },
//         { name: "Distributed Systems", level: 90 },
//         { name: "AdTech", level: 95 }
//       ]
//     }
//   ];

//   return (
//     <section id="skills" className="py-20 bg-white">
//       <div className="container mx-auto px-4">
//         <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Technical Skills</h2>
        
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           {skillCategories.map((category, categoryIndex) => (
//             <div key={categoryIndex} className="bg-gray-50 rounded-lg p-6 shadow-sm border border-gray-100">
//               <h3 className="text-xl font-bold mb-4 text-gray-800">{category.title}</h3>
//               <div className="space-y-4">
//                 {category.skills.map((skill, skillIndex) => (
//                   <div key={skillIndex}>
//                     <div className="flex justify-between mb-1">
//                       <span className="font-medium">{skill.name}</span>
//                       <span className="text-gray-500 text-sm">{skill.level}%</span>
//                     </div>
//                     <div className="w-full bg-gray-200 rounded-full h-2">
//                       <div
//                         className="bg-blue-600 h-2 rounded-full"
//                         style={{ width: `${skill.level}%` }}
//                       ></div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Skills;

// Skills.jsx
import React from "react";
import CircleProgress from "./CircleProgress";

const Skills = () => {
  const skillCategories = [
    {
      title: "Programming Languages",
      skills: [
        { name: "Java", level: 95 },
        { name: "Python", level: 90 },
        { name: "Golang", level: 75 },
        { name: "JavaScript/React.js", level: 70 }
      ]
    },
    {
      title: "Databases & Data Stores",
      skills: [
        { name: "Elasticsearch", level: 90 },
        { name: "Redis", level: 90 },
        { name: "MongoDB", level: 85 },
        { name: "ClickHouse", level: 85 },
        { name: "PostgreSQL", level: 80 },
        { name: "Cassandra", level: 75 },
        { name: "KyotoTycoon", level: 90 }
      ]
    },
    {
      title: "Cloud & DevOps",
      skills: [
        { name: "AWS Cloud", level: 85 },
        { name: "Docker", level: 80 },
        { name: "CI/CD", level: 75 }
      ]
    },
    {
      title: "Frameworks & Technologies",
      skills: [
        { name: "Spring Framework", level: 90 },
        { name: "Dropwizard Framework", level: 90 },
        { name: "Apache Kafka", level: 85 },
        { name: "Apache Spark", level: 85 },
        { name: "GIS", level: 95 },
        { name: "Distributed Systems", level: 90 },
        { name: "AdTech", level: 95 }
      ]
    }
  ];

  return (
    <section id="skills" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          Technical Skills
        </h2>        
        {/* 4 columns on md+ screens, 1 column on smaller */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {skillCategories.map((category, idx) => (
            <div key={idx}>
              {/* Category Title */}
              <h3 className="text-lg font-semibold mb-3 text-gray-800">
                {category.title}
              </h3>

              {/* Skills (each with a small circle) */}
              <div className="flex flex-wrap gap-y-4">
                {category.skills.map((skill, i) => (
                  <div
                    key={i}
                    className="flex items-center gap-2 w-1/2 sm:w-1/3 md:w-full"
                  >
                    <CircleProgress percentage={skill.level} />
                    <span className="text-sm mt-1 text-gray-700">
                      {skill.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
