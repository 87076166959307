import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Blogs = () => {
  // Sample blog data - replace with your actual blog content
  const [blogs] = useState([
    {
      id: 1,
      title: "How Near reduced latency by four times and achieved 99.9% uptime by migrating to Amazon ElastiCache",
      excerpt: "In this post, we discuss why Near chose to migrate to Amazon ElastiCache for Redis...",
      date: "Jul 23, 2024",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["AWS", "AWS Blogs", "Database", "Redis", "Adtech"],
      image: "/blogs/blog-1-main.png",
      readTime: "10 min read"
    },
    {
      id: 2,
      title: "Real-Time Bidding in Mobile AdTech: Core Entities & Seamless Integration",
      excerpt: "Explore how integrated adtech platforms—from SSPs to DSPs—deliver personalized ads in under 100 milliseconds...",
      date: "March 12, 2025",
      author: "Ashwin Nair",
      category: "Technology",
      tags: ["RTB", "Mobile Advertising", "DSP", "SSP", "Exchange", "Adtech"],
      image: "/blogs/blog-2-main.jpeg",
      readTime: "8 min read"
    },
    {
      id: 3,
      title: "The Art of Travel Planning: A Clear Itinerary for a Relaxed Vacation",
      excerpt: "Discover how detailed travel planning not only reduces stress and saves time but also enhances spontaneity, ensuring every moment of your vacation is filled with purpose and relaxation....",
      date: "March 17, 2025",
      author: "Ashwin Nair",
      category: "Travel",
      tags: ["Travel Planning", "Itinerary", "Vacation Tips", "Stress-Free Travel", "Travel Organization"],
      image: "/blogs/blog-3-main.jpeg",
      readTime: "8 min read"
    },
    // {
    //   id: 4,
    //   title: "Building Better React Components",
    //   excerpt: "Best practices and patterns I've learned for creating maintainable React components...",
    //   date: "January 25, 2025",
    //   author: "Your Name",
    //   category: "Development",
    //   tags: ["a", "b"],
    //   image: "/api/placeholder/800/450",
    //   readTime: "7 min read"
    // }
  ]);  
  
  return (
    <section id="blogs" className="py-14 bg-gray-50">
      <div className="container mx-auto px-4">        
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">My Blog</h2>        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.slice(0, 3).map((blog) => (
            <div key={blog.id} className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-[1.02] duration-300">
              <Link to={`/blog/${blog.id}`}>
                <img
                  loading="lazy" 
                  src={blog.image} 
                  alt={blog.title} 
                  className="w-full h-48 object-cover"
                />
              </Link>
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">{blog.category}</span>                
                  <span className="mx-2">•</span>
                  <span>{blog.date}</span>
                  <span className="mx-2">•</span>
                  <span>{blog.readTime}</span>
                </div>
                <div className="flex flex-wrap gap-2 py-1 ">
                  {blog.tags.map((tag, index) => (
                    <span 
                      key={index} 
                      className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <Link to={`/blog/${blog.id}`}>
                  <h3 className="text-xl font-bold mb-2 hover:text-blue-600 transition-colors">{blog.title}</h3>
                </Link>
                <p className="text-gray-600 mb-4">{blog.excerpt}</p>
                <Link 
                  to={`/blog/${blog.id}`}
                  className="text-blue-600 font-medium inline-flex items-center hover:text-blue-800 transition-colors"
                >
                  Read More
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>      
        <div className="text-center mt-10">          
          <Link to="/blog" className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors shadow-md inline-flex items-center justify-center">
            View All
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
        </div>        
      </div>
    </section>
  );
};

export default Blogs;